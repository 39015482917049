import React from "react";
import cuadro from "../assets/images/icon/cuadro1.svg";
import cuadro2 from "../assets/images/icon/cuadro2.svg";
import check from "../assets/images/icon/well.svg";

const Gracias = () => {
 return (
  <div
   class="relative rounded-full  w-16 h-16"
   style={{ background: "#8163FF" }}
  >
   <div class="inline-flex justify-center items-center w-full h-full">
    <div class="transform rotate-45 absolute w-8 h-1 bg-white"></div>
    <div class="transform -rotate-45 absolute w-8 h-1 bg-white"></div>
   </div>
  </div>
 );
};

export default Gracias;
