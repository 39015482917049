import axios from "axios";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
import cuadro from "../../assets/images/icon/cuadro1.svg";
import cuadro2 from "../../assets/images/icon/cuadro2.svg";
import check from "../../assets/images/icon/well.svg";

const ResponsePay = () => {
 const query = window.location.search;
 const history = useHistory();
 // let email = JSON.parse(localStorage.getItem("newStore")).dataStore.email;
 const parametros = queryString.parse(query);
 let mensaje, data;
 try {
  mensaje = decodeURIComponent(parametros.mensaje);
  data = JSON.parse(mensaje);
  console.log(data);
 } catch (error) {
  history.push("/");

  return null;
 }

 return (
  <div>
   {data.status === 200 ? (
    <>
     <div class="h-screen  py-16   px-32 flex gap-5 justify-content-between">
      <div className="mt-auto">
       <img src={cuadro} alt="Cuadro" />
      </div>
      <div className=" flex flex-col items-center justify-center">
       <img src={check} alt="Cuadro" />
       <h2>¡Suscripción exitosa!</h2>
       <p>Enviámos un correo a {data.email}</p>
       <a href="https://panel.powerbizz.com/">
        <button className="theme-btn-one">Comenzar</button>
       </a>
      </div>
      <div>
       <img src={cuadro2} alt="Cuadro" />
      </div>
     </div>
    </>
   ) : (
    <div class="h-screen  py-16   px-32 flex gap-5 justify-content-between">
     <div className="mt-auto">
      <img src={cuadro} alt="Cuadro" />
     </div>
     <div className=" flex flex-col items-center justify-center">
      <div
       class="relative rounded-full  w-16 h-16"
       style={{ background: "#8163FF" }}
      >
       <div class="inline-flex justify-center items-center w-full h-full">
        <div class="transform rotate-45 absolute w-8 h-1 bg-white"></div>
        <div class="transform -rotate-45 absolute w-8 h-1 bg-white"></div>
       </div>
      </div>{" "}
      <h2> Hubo un fallo en tu transacción</h2>
      <ul className="mt-2 text-sm text-gray-500">
       <li>Número de pedido: {data.purchaseNumber}</li>
       {/* <li>Fecha y hora del pedido: {fechaHora.toLocaleString()}</li> */}
       <li>DESCRIPCIÓN: {data.message || data.description}</li>
      </ul>
      <Link to={"nueva-tienda/checkout"}>
       <button className="theme-btn-one">Volver</button>
      </Link>
     </div>
     <div>
      <img src={cuadro2} alt="Cuadro" />
     </div>
    </div>
   )}
  </div>
 );
};
export default ResponsePay;
