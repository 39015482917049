import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppPB from './AppPB'
import reportWebVitals from './reportWebVitals'
// import 'tw-elements';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
import "react-toastify/dist/ReactToastify.css"
import "./assets/main.scss"

ReactDOM.render(
  <React.StrictMode>
    <div className="h-100">
      <AppPB />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
