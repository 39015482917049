import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import Home from './pages/Home'
import ScrollToTop from "./views/ScrollToTop";
import ResponsePay from "./components/new-store/ResponsePay";
import ResponsePrueba from "./pages/ResponsePrueba";
import Gracias from "./pages/Gracias";
import TagManager from "react-gtm-module";

const tagManagerArgs = { gtmId: "GTM-T2B7KP6" };
TagManager.initialize(tagManagerArgs);

const Home = React.lazy(() => import("./pages/Home"));
const NewStore = React.lazy(() => import("./pages/NewStore"));
const CommercialData = React.lazy(() =>
 import("./components/new-store/comercial-data/CommercialData")
);
const Checkout = React.lazy(() =>
 import("./components/new-store/checkout/Checkout")
);
const TermsConditions = React.lazy(() =>
 import("./components/home/footer/TermsConditions")
);

class AppPB extends React.Component {
 constructor() {
  super();
  this.state = {
   logged: [],
  };
 }

 render() {
  console.log("welcome PB");
  return (
   <div>
    <React.Suspense fallback={<h1>Cargando...</h1>}>
     <Router>
      <ScrollToTop>
       <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/nueva-tienda" render={() => <NewStore />} />
        <Route
         exact
         path="/nueva-tienda/datos-comercio"
         render={() => <CommercialData />}
        />
        <Route
         exact
         path="/nueva-tienda/checkout"
         render={() => <Checkout />}
        />

        <Route exact path="/responsePay" render={() => <ResponsePay />} />
        <Route exact path="/gracias" render={() => <Gracias />} />
       </Switch>
      </ScrollToTop>
     </Router>
    </React.Suspense>
   </div>
  );
 }
}

export default AppPB;
